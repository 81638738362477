import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import styled from "styled-components";
import 'styled-components/macro';
import "twin.macro";
import tw from "twin.macro";




const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-200 max-w-4xl`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24 rounded-xl drop-shadow-md`}

  // background: rgb(6,191,197);
  // background: linear-gradient(322deg, #0B182B 10%, #072A47 90%);
`;
const Subheading = tw(SubheadingBase)`mb-4 text-turky`;
const Heading = tw(SectionHeading)`w-full text-gray-100 drop-shadow-md shadow-black`;
const Description = tw(SectionDescription)`w-full text-center text-gray-500`
const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/2 max-w-sm`}
`;

const CardBase = styled.div`
  ${tw`flex flex-col sm:flex-col items-start sm:items-start text-left sm:text-left h-full my-2 px-2 py-2 w-full`}

  .bubble {
    ${tw`text-left w-full rounded-2xl p-3 px-6 flex-shrink-0 bg-turky relative drop-shadow-md`}
  }

  .title {
    ${tw`font-bold mt-4 tracking-wide text-xl text-gray-100 leading-none`}
  }

  .description {
    ${tw`mt-2 sm:mt-2 text-gray-100`}
  }
`;

const CardLeft = styled(CardBase)`
  .bubble {
    ${tw`ml-8 md:ml-2`}
    background: rgb(148,27,128);
    background: linear-gradient(219deg, rgba(148,27,128,1) 10%, rgba(213,93,158,1) 90%);

    :before {
      left: 6px;
      bottom: -12px;
      transform: rotate(15deg);
      border-left-color: rgba(213,93,158,1);
      border-top-color: rgba(213,93,158,1);
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-width: 6px 6px 10px 10px;
      border-style: solid;
      ${tw`border-r-transparent border-b-transparent`};
    }
  }
`
const CardRight = styled(CardBase)`
  .bubble {
    ${tw`mr-8 md:mr-2 md:mt-8`}
    background: rgb(6,191,197);
    background: linear-gradient(322deg, rgba(6,191,197,1) 10%, rgba(25,104,141,1) 100%);

    :before {
      right: 0;
      bottom: -6px;
      transform: rotate(-55deg);
      border-left-color: rgba(6,191,197,1);
      border-top-color: rgba(6,191,197,1);
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-width: 6px 6px 10px 10px;
      border-style: solid;
      ${tw`border-r-transparent border-b-transparent`};
    }
  }
`

export default () => {

  const cards = [
    {
        icon: "📚",
        title: "Real-time Corrections",
        description: "Poli corrects your grammar and and suggests improvements"
    },
    {
      icon: "💬",
      title: "Fluency",
      description: "Poli speaks like a native speaker in all languages",
    },
    {
      icon: "🔑",
      title: "Explanations",
      description: "Poli explains sentences, breaking down key vocabulary and grammar concepts"
    },
    {
        icon: "🔡",
        title: "Translations",
        description: "Poli can translate anything that you don't understand"
    },
    {
      icon: "🍀",
      title: "Examples",
      description: "Poli can show you examples of how words and phrases are used in different context"
    },
    {
        icon: "🗣️",
        title: "Pronunciations",
        description: "Poli can pronounce words and phrase like a native speaker"
    }

  ];


  return (
    <ThreeColumnContainer>
        <Subheading>With Poli, you have all the tools for fluency at your fingertips.</Subheading>
        <Heading>Features</Heading>
        <Description>
            Poli helps you learn a new language through conversation just as a native speaker would.<br/>
            <br/>
            Here are just some of Poli's features:
        </Description>
        <VerticalSpacer />
        {cards.map((card, i) => {
          let Card = CardLeft
          if(!(i % 2)) {
            Card = CardRight
          }
          return (
            <Column key={i}>
                <Card>
                    <div className="bubble">
                      <span className="title">{card.icon}&nbsp;&nbsp;{card.title}</span>
                      <p className="description" dangerouslySetInnerHTML={{__html: card.description}} />
                    </div>
                </Card>
            </Column>
        )})}
    </ThreeColumnContainer>
)};