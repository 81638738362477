import { useEffect } from "react";
import 'styled-components/macro';
import "twin.macro";
import tw from "twin.macro";

import { Container } from "components/misc/Layouts";

import LogoBeta from "p_images/poli-icon.svg";

import { AuthContext } from 'App';
import { useContext } from "react";
import { Navigate } from "react-router-dom";


const Page = tw(Container)`bg-gray-200 -mx-8 py-8 px-8 min-h-screen p-0`
const MainContainer = tw(Container)`w-64 mx-auto`

export default () => {
    const authContext = useContext(AuthContext)

    useEffect(() => {
        if (authContext.isInit && !authContext.isAuthenticated) {
            authContext.login_with_telegram()
        }
    }, [authContext.isInit, authContext.isAuthenticated])

    if (authContext.isDone()) {
        if (authContext.isAuthenticated) {
            return <Navigate to="/app/users/me" />
        } else {
            return <Navigate to="/" state={{ authError: true }} />
        }
    }

    return (
        <Page>
            <MainContainer>
                <center>
                    <img css={[tw`w-full`]} src={LogoBeta} alt="Beta Logo" />
                    <p css={[tw`mt-4`]}>
                        <strong>Logging you in...</strong>
                    </p>
                </center>
            </MainContainer>
        </Page>
    )
}