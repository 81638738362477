import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import styled from "styled-components";
import 'styled-components/macro';
import "twin.macro";
import tw from "twin.macro";


import FacebookIcon from "p_images/icon-facebook.svg";
import TelegramIcon from "p_images/icon-telegram.svg";
import ViberIcon from "p_images/icon-viber.svg";
import WebIcon from "p_images/icon-web.svg";


const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`
const Subline = tw(SectionDescription)`w-full text-center text-sm`
const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.a`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  cursor: pointer;

  .imageContainer {
    ${tw`border-2 text-center rounded-full p-3 flex-shrink-0`}

    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`sm:ml-6 mt-5 sm:mt-5`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
    sup {
      ${tw`text-sm text-gray-500`}
    }
  }

  .description {
    ${tw`mt-1 sm:mt-4 text-secondary-300 text-sm whitespace-nowrap`}
    code {
      ${tw`text-secondary-500 rounded-md`}
    }
  }
`;

export default ({openModal}) => {

  const cards = [
    {
      imageSrc: TelegramIcon,
      title: "Telegram",
      description: "click here or<br/>send <code>/start</code> to <code>@poli_app_bot</code>",
      url: "https://t.me/poli_app_bot"
    },
    {
        imageSrc: WebIcon,
        title: "Web",
        description: "click here to<br/>open the <code>Web Client</code>",
        onClick: openModal
    },
    {
        imageSrc: ViberIcon,
        title: "Viber",
        description: "click here or<br/>send <code>/start</code> to <code>Poli</code>",
        url: "viber://pa/info?uri=poli-bot"
    },
    {
      imageSrc: FacebookIcon,
      title: "Facebook",
      soon: true,
      description: "* Coming soon!",
      url: "#Clients"
    },
  ];


  return (
    <ThreeColumnContainer>
        <Subheading>Chat anywhere and anytime</Subheading>
        <Heading>Supported Messengers</Heading>
        <Description>
            We support a wide range of messengers, with many more to come.
        </Description>
        <VerticalSpacer />
        {cards.map((card, i) => (
        <Column key={i}>
            <Card href={card.url || null} onClick={card.onClick ? (e) => card.onClick(e) : null} target="_blank">
                <span className="imageContainer">
                    <img src={card.imageSrc || WebIcon} alt="" />
                </span>
                <span className="textContainer">
                    <span className="title">{card.title} {card.soon && <sup>*</sup>}</span>
                    {card.description && <p className="description" dangerouslySetInnerHTML={{__html: card.description}} />}
                </span>
            </Card>
        </Column>
        ))}
        <Subline>Missing your favorite messenger?<br/> 👉 Drop us a line to <a css={[tw`text-primary-500`]} href="mailto: feedback@poli.xyz">feedback@poli.xyz</a></Subline>
        <VerticalSpacer />
    </ThreeColumnContainer>
)};