import axios from "axios";

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || "",
    timeout: 1000
});

let interceptor

export const set_token = (token) => {
    interceptor = apiClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}

export const unset_token = () => {
    apiClient.interceptors.request.eject(interceptor)
}