import styled from "styled-components";
import 'styled-components/macro';
import "twin.macro";
import tw from "twin.macro";
//eslint-disable-next-line


import Examples from "p_images/example.png";
import LogoBeta from "p_images/poli-icon.svg";

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative ml-4 mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Subheading = tw.h2`text-xl md:text-xl lg:text-xl text-gray-500 leading-tight mb-2`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative text-center sm:text-right mx-auto lg:mx-0`}
`;

const Button = styled.a`
    ${tw`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-secondary-500 focus:shadow-outline mt-6 md:mt-4 lg:mt-0 ml-8 whitespace-nowrap`}
`
const ButtonSecondary = tw(Button)`bg-secondary-200 sm:ml-8 ml-0`

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center relative`;



export default () => {
    return (
        <TwoColumn>
          <LeftColumn>
            <Subheading>
              Level up your language learning with Poli.
            </Subheading>
            <Heading>
                Ready, set, <span css={[tw`text-primary-500`]}>chat!</span>
            </Heading>
            <Paragraph>
                Chat with <b>Poli</b>, our <b>fun</b> and <b>witty AI</b>, to get <b>real practice in conversation</b>.<br/>
                <br/>
                As opposed to other language learning tools, Poli will keep you <b>engaged in a conversation</b>.<br/>
                <br/>
                While you are <b>having fun and interacting</b>, Poli will also <b>correct your mistakes</b> and tell you how to phrase things to <b>sound more fluent</b>.<br/>
                <br/>
                Write in your native language and Poli will help you as well as provide <b>lessons, examples, translations, pronunciations</b> and more!<br/>
                <br/>
                Poli is <b>currently available</b> to chat in:<br/>
                <span css={tw`text-2xl`}>🇺🇸 🇪🇸 🇫🇷 🇧🇷 🇮🇹 🇩🇪 🇷🇺 🇹🇷 🇺🇦</span>
            </Paragraph>
            <Actions>
                <ButtonSecondary href="#HowTo">How to</ButtonSecondary>
                <Button href="#Clients">Start chatting</Button>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
                <img css={[tw`w-full relative z-10`]} src={Examples} alt="Examples" />
                <img css={[tw`w-40 sm:w-80 md:w-96 lg:w-64 xl:w-72 absolute top-0 left-0`]} src={LogoBeta} alt="Beta Logo" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
    )
}