const KEY = "web_id"

const get_stored_web_id = () => {
  const web_id = localStorage.getItem(KEY)
  console.log("web_id restored")
  return web_id
}

const set_web_id = (web_id) => {
  try {
    localStorage.setItem(KEY, web_id)
    console.log("web_id stored")
  } catch(err) {
    console.warn("LocalStorage not available")
  }
}

// should be made bigint, maybe???
const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}
const max = 2147483647

const get_web_id = () => {
  let web_id
  try {
    web_id = get_stored_web_id()
    if(web_id == null || typeof web_id == undefined) {
      throw Error()
    }
  } catch(err) {
    web_id = getRandomInt(max)
    set_web_id(web_id)
  }

  return web_id
}

const web_id = get_web_id()

class ActionProvider {
    constructor(
        createChatBotMessage,
        setStateFunc,
        createClientMessage,
        stateRef,
        createCustomMessage,
        ...rest
      ) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
        this.stateRef = stateRef;
        this.createCustomMessage = createCustomMessage;
      }

    handle = async (message) => {
      const data = {
        "web_id": `${web_id}`,
        "text": message
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/plugin/web/w`, {
            method: "POST",
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const response_messages = await response.json()
        const messages = response_messages.map(m => this.createChatBotMessage(m))
        this.addMessageToBotState(messages);
      }
      catch (err) {
        console.error(err)
        const messages = this.createChatBotMessage(
          "Sorry, something went wrong"
        );
        this.addMessageToBotState(messages);
      }

    };

    // TODO: remove
    handleStart = async (message) => {
        if(message === "/start") {
          this.setState((state) => ({...state, started: true}))
          await this.handle(message)
        } else {
          const messages = this.createChatBotMessage(
            "Please send /start"
          );
          this.addMessageToBotState(messages);
        }

    };

    addMessageToBotState = (messages, newState) => {
      if (Array.isArray(messages)) {
        this.setState((state) => ({
          ...state,
          ...newState,
          messages: [...state.messages, ...messages],
          gist: "",
          infoBox: "",
        }));
      } else {
        this.setState((state) => ({
          ...state,
          ...newState,
          messages: [...state.messages, messages],
          gist: "",
          infoBox: "",
        }));
      }
    };
  }

  export default ActionProvider;