import AuthPage from "pages/AuthPage";
import LandingPage from "pages/LandingPage";
import ProfilePage from "pages/ProfilePage";

import { apiClient, set_token, unset_token } from 'clients/apiClient';
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";


export const AuthContext = createContext()

const TOKEN_KEY = "aToken"

const AuthProvider = ({ children }) => {
    const [isInit, setIsInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const isDone = useCallback(() => {
        return isInit && !isLoading && isAuthenticated !== null
    }, [
        isInit, isLoading, isAuthenticated
    ])

    useEffect(() => {
        const init = async (token) => {
            console.log("init")
            try {
                set_token(token)

                await apiClient.get("/users/me")
                setIsAuthenticated(true);
                console.log("auth with stored token")
            } catch (err) {
                remove_token()
                console.log(err)
                console.log("stored token invalid")
            }
            setIsInit(true)
        }

        let token = null
        try {
            token = window.localStorage.getItem(TOKEN_KEY);
        } catch(err) {
            console.log("localStorage not available")
        }
        if (token && token != null && token !== 'undefined') {
            init(token)
        } else {
            setIsInit(true)
            console.log("no stored token found")
        }
    }, [])

    const login_with_telegram = async () => {
        if (isLoading) {
            console.log("login with telegram already running")
            return
        }
        console.log("login with telegram")
        setIsLoading(true)
        try {
            const queryParams = new URLSearchParams(window.location.search);

            const client = queryParams.get("poli_client")

            if (client === "telegram") {
                queryParams.delete("poli_client")
                const response = await apiClient.get("/auth/telegram", { params: queryParams })
                console.log("login with telegram done")
                handle_login(response.data.access_token)
            } else {
                throw Error(`Client not supported ${client}`)
            }
            setIsLoading(false)
        } catch (error) {
            console.error(error)
            console.log("login with telegram failed")
            setIsLoading(false)
            setIsAuthenticated(false)
        }
    }

    const handle_login = (token, callback) => {
        set_token(token)

        try {
            window.localStorage.setItem(TOKEN_KEY, token);
            console.log("token stored")
        } catch (err) {
            console.error(err)
            console.log("token not stored")
        }

        setIsAuthenticated(true);
        console.log("login done")

        callback && callback();
    }

    const remove_token = () => {
        unset_token()

        try {
            window.localStorage.removeItem(TOKEN_KEY);
            console.log("token removed")
        } catch (err) {
            console.error(err)
        }
    }

    const logout = (callback) => {
        remove_token()

        setIsAuthenticated(false);
        console.log("logout done")

        callback && callback();
    };

    const value = { isInit, isDone, isAuthenticated, login_with_telegram, logout };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const Protected = ({ children }) => {
    const authContext = useContext(AuthContext)
    const navigate = useNavigate();

    if (authContext.isInit && !authContext.isAuthenticated) {
        navigate("/", { state: { authError: true }})
    }

    return children
}

export default () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/app/auth" element={<AuthPage />} />
                    <Route path="/app/users/:user_id" element={<Protected><ProfilePage /></Protected>} />
                    <Route path="/" element={<LandingPage />} />
                </Routes>
            </Router>
        </AuthProvider>
    )
}