import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import styled from "styled-components";
import tw from "twin.macro";

import LogoBeta from "p_images/poli-icon.svg";

const Container = tw.div`relative md:w-3/4 lg:w-3/4  mx-auto`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-4 md:py-6`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled(Column)`
    ${tw`md:w-3/12 flex-shrink-0 h-80 md:h-auto relative`}
`;
const Img = tw.img`w-2/3 md:w-full mx-auto`
const TextColumn = styled(Column)`
  ${tw`md:w-9/12 mt-16 md:mt-0 md:ml-12 lg:ml-16 md:order-first`}
`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-200`;

const Ul = tw.ul`list-inside`;
const Li = tw.li`list-disc`;

export default () => {

  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Subheading>An AI-powered native speaker at your fingertips...</Subheading>
            <Heading>About Poli</Heading>
            <Description>
                Practice makes <b>perfect</b>!<br/>
                <br/>
                The best way to <b>actually</b> learn to speak a language is through conversation. That's where <b>Poli</b> comes in!<br/>
                <br/>
                Poli is an <b>AI-driven chatbot</b> fluent in <b>multiple languages</b> and equipped with powerful <b>language learning features</b>.<br/>
                <br/>
                The awesome thing about Poli is that you are actually chatting with an AI - so you can chat about anything you want!<br/> Ask Poli:<br/>
                <br/>
                <Ul>
                  <Li><b>What's your favorite place to visit in Greece?</b></Li>
                  <Li><b>What kind of music should I listen to to learn German? I like rock and reggae!</b></Li>
                  <Li><b>Can you teach me how to order a coffee at the café?</b></Li>
                  <Li><b>What is one item on your bucket list?</b></Li>
                  <Li><b>What is the meaning of life?</b></Li>
                </Ul>
                <br/>
                Let your creativity and imagination run wild! You can bet that Poli will always have great replies and engage you in fun and deep conversation.<br />
                <br/>
                We harness the power of <b>GPT-4</b> and other state-of-the-art <b>NLP</b> and <b>LLM</b> technology to create engaging and fruitful conversation.<br/>
                <br/>
                Our passion and expertise enables us to craft the perfect <b>conversation partner</b> and <b>tutor</b> so you can <b>level-up your language learning</b>.<br/>
                <br/>
                Ready, set, <b>chat!</b>
            </Description>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <Img src={LogoBeta} alt="" />
        </ImageColumn>
      </TwoColumn>
    </Container>
  );
};
