import App from "App";
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from "react-modal";
import 'styled-components/macro';
import GlobalStyles from 'styles/GlobalStyles';
import "tailwindcss/lib/css/preflight.css";

Modal.setAppElement('#root')
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>
);