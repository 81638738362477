import { AuthContext } from 'App';
import { apiClient } from 'clients/apiClient';
import { Container, Content2Xl } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Nav from 'p_components/nav';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import 'styled-components/macro';
import "twin.macro";
import tw from "twin.macro";

const Page = tw(Container)`bg-gray-200 -mx-8 py-8 px-8 min-h-screen p-0 -mt-8`
const MainContainer = tw(Container)`w-10/12 mt-16 mx-auto`

const Input = tw.input`w-full px-8 py-4 mt-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`;
const Select = tw.select`w-full px-8 py-2.5 mt-2 rounded-lg font-medium bg-gray-100 border border-gray-100 border-7 text-sm focus:outline-none focus:border-white focus:bg-white block `
const Label = tw.label`w-full font-medium text-sm mt-5 mt-5 first:mt-0 inline-block`;
const CheckBoxContainer = tw.div`flex items-center mb-1`
const CheckBoxLabel = tw.label`w-full font-medium text-sm ml-4`;
const Checkbox = tw.input`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`

const Submit = tw.button`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-secondary-500 focus:shadow-outline mt-6 md:mt-4 lg:mt-0 ml-8 whitespace-nowrap`

const FormContainer = tw(Container)`flex flex-col`
const FormRow = tw(Container)`md:flex md:flex-row mb-8`
const FormPartContainer = tw(Container)`w-full mb-8 md:mb-0 md:mx-4 lg:mx-8 md:flex-1 md:w-64`
const Notify = tw.div`p-4 w-full`
const NotifySuccess = tw(Notify)`bg-green-300 rounded-lg text-white`
const NotifyError = tw(Notify)`bg-red-300 rounded-lg text-white`

const H1 = tw.h1`text-lg mb-6`

const Small = tw.small`h-8 text-gray-600`

const initFormValues = {
    user: {
        name: null,
        email: null,
        native_locale: null
    },
    conversation: {
        level: null,
        native_locale: null,
        reminders: null,
        always_send_tts_audio: null
    }
}

export default () => {
    const { user_id } = useParams();
    // form data
    const [user, setUser] = useState(null)
    const [conversation, setConversation] = useState(null)
    // form options
    const [options, setOptions] = useState(null)
    // state
    const authContext = useContext(AuthContext)

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingOptions, setIsLoadingOptions] = useState(true)
    const [error, setError] = useState(false)
    const [notify, setNotify] = useState(null)

    // load options right away
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await apiClient.get("/api/v1/profile/options")
                console.debug(response.data)
                setOptions(response.data)
            } catch (err) {
                setError(true)
            }
            setIsLoadingOptions(false)
        }

        fetchOptions()
    }, [])

    // fetch user only after authentication finished
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiClient.get("/api/v1/profile/me")
                console.debug(response.data)
                setUser(response.data.user)
                setConversation(response.data.conversation)
            } catch (err) {
                authContext.logout()
                setError(true)
            }
            setIsLoading(false)
        }

        if (authContext.isDone()) {
            fetchUser()
        }

    }, [user_id, authContext])

    const handleOnChangeUser = (e) => {
        const userUpdated = {
            ...user,
            [e.target.name]: e.target.value
        }
        setUser(userUpdated)
        console.debug({ userUpdated })
    }

    const handleOnChangeConversation = (e) => {
        const conversationUpdated = {
            ...conversation,
            [e.target.name]: e.target.value
        }
        setConversation(conversationUpdated)
        console.debug({ conversationUpdated })
    }

    const toggleCheckboxConversation = (e) => {
        const conversationUpdated = {
            ...conversation,
            [e.target.name]: !conversation[e.target.name]
        }
        setConversation(conversationUpdated)
        console.debug({ conversationUpdated })
    }

    const handleSubmit = async () => {
        try {
            const response = await apiClient.post("/api/v1/profile/me", {
                user,
                conversation
            })

            if (response.status !== 200) {
                throw Error()
            }

            setNotify(true)
        } catch (err) {
            setNotify(false)
        }
        setTimeout(5000, () => {
            setNotify(null)
        })
    }

    const get_content = () => {
        if (isLoading || isLoadingOptions) {
            // while loading
            return (
                <p css={[tw`mt-4`]}>
                    <strong>Loading, please wait...</strong>
                </p>
            )
        } else if ((!isLoading || !isLoadingOptions) && error) {
            // show error on data loading error
            return (
                <p css={[tw`mt-4`]}>
                    <strong>An error occured, please try again</strong>
                </p>
            )
        } else if ((!isLoading && !isLoadingOptions) && user && conversation && options) {
            return (
                <div>
                    <H1>Conversation Settings</H1>
                    <FormContainer>
                        <FormRow>
                            {notify === null ||
                                (
                                    notify
                                    ? <NotifySuccess>Profile updated successfuly</NotifySuccess>
                                    : <NotifyError>An error occured, please try again</NotifyError>
                                )
                            }
                        </FormRow>
                        <FormRow>
                            <FormPartContainer>
                                <Label htmlFor="name">
                                    Name
                                    <Input
                                        name="name"
                                        value={user.name || ""}
                                        placeholder="name"
                                        onChange={handleOnChangeUser}
                                    />
                                    <Small>&nbsp;</Small>
                                </Label>
                                <Label htmlFor="email">
                                    Email
                                    <Input
                                        name="email"
                                        value={user.email || ""}
                                        placeholder="email"
                                        onChange={handleOnChangeUser}
                                    />
                                    <Small>&nbsp;</Small>
                                </Label>
                                <Label htmlFor="native_locale">
                                    Your native language
                                    <Select
                                        name="native_locale"
                                        onChange={handleOnChangeUser}
                                        defaultValue={user.native_locale}
                                    >
                                        {user.native_locale === null && <option>Choose a language</option>}
                                        {options.locales.map(
                                            locale => <option
                                                value={locale.short}
                                                key={locale.short}
                                            >{locale.flag}  {locale.name}</option>
                                        )}
                                    </Select>
                                    <Small>Poli explains grammar and lessons in this language</Small>
                                </Label>
                            </FormPartContainer>
                            <FormPartContainer>
                                <Label htmlFor="target_locale">
                                    Language you want to learn
                                    <Select
                                        name="target_locale"
                                        onChange={handleOnChangeConversation}
                                        defaultValue={conversation.target_locale}
                                    >
                                        {conversation.target_locale === null && <option>Choose a language</option>}
                                        {options.locales.map(
                                            locale => <option
                                                value={locale.short}
                                                key={locale.short}
                                            >{locale.flag}  {locale.name}</option>
                                        )}
                                    </Select>
                                    <Small>Poli talks to you in this language</Small>
                                </Label>
                                <Label htmlFor="level">
                                    Level
                                    <Select
                                        name="level"
                                        onChange={handleOnChangeConversation}
                                        defaultValue={conversation.level || ""}
                                    >
                                        {conversation.level === null && <option value="">Choose a level</option>}
                                        {options.levels.map(
                                            level => <option
                                                value={level.code}
                                                key={level.code}
                                            >{level.name}</option>
                                        )}
                                    </Select>
                                    <Small>How well you speak this language already</Small>
                                </Label>
                            </FormPartContainer>
                            <FormPartContainer>
                                <Label htmlFor="always_send_tts_audio">
                                    <CheckBoxContainer>
                                        <Checkbox
                                            name="always_send_tts_audio"
                                            type="checkbox"
                                            defaultChecked={conversation.always_send_tts_audio}
                                            value="always_send_tts_audio"
                                            onChange={toggleCheckboxConversation}
                                        />
                                        <CheckBoxLabel htmlFor="always_send_tts_audio">Pronunciation   </CheckBoxLabel>
                                    </CheckBoxContainer>
                                    <Small>Poli automatically sends voice messages</Small>
                                </Label>
                                <Label htmlFor="reminders">
                                    <CheckBoxContainer>
                                        <Checkbox
                                            name="reminders"
                                            type="checkbox"
                                            defaultChecked={conversation.reminders}
                                            value="reminders"
                                            onChange={toggleCheckboxConversation}
                                        />
                                        <CheckBoxLabel htmlFor="reminders">Reminders   </CheckBoxLabel>
                                    </CheckBoxContainer>
                                    <Small>Poli sends you up to 3 practice reminders</Small>
                                </Label>
                            </FormPartContainer>
                        </FormRow>
                        <FormRow>
                            <Submit onClick={handleSubmit}>Save</Submit>
                        </FormRow>
                    </FormContainer>
                </div>
            )
        } else {
            setError(true)
            return <></>
        }
    }

    return (
        <AnimationRevealPage disabled>
            <Page>
                <Content2Xl>
                    <Nav internal />
                </Content2Xl>
                <MainContainer>
                    {get_content()}
                </MainContainer>
            </Page>
        </AnimationRevealPage>
    )
}