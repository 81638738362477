class MessageParser {
    constructor(actionProvider) {
      this.actionProvider = actionProvider;
    }

    parse = (message) => {
      // if(!this.actionProvider.stateRef.started) {
      //   return this.actionProvider.handleStart(message);
      // }

      return this.actionProvider.handle(message);
    };
  }

  export default MessageParser;