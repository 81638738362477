import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import tw from "twin.macro";

const Container = tw.div`relative`;
const Row = tw.div`flex flex-col md:flex-row justify-center items-center`;
const Column = tw.div`w-full mx-auto md:mx-0 lg:w-1/2 mt-16 md:mt-16 flex flex-col items-center`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-8`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default () => {
  const steps = [
    {
      heading: "Choose your Messenger",
      description: "Select your favorite Messenger like Telegram, Viber or the Web and send \"/start\"."
    },
    {
      heading: "Set up the Conversation",
      description: "Select your native language, the language you want to practice and your level of expertise."
    },
    {
      heading: "Start chatting",
      description: "Bring up a topic you're interested in or ask Poli to propose something to talk about."
    },
    {
      heading: "Learn",
      description: "Use features like /translate, /explain and /pronounce to help you dive deeper into the language."
    }
  ];

  return (
    <Container>
      <Row>
        <Column>
            <Subheading>Ready, Set, Chat!</Subheading>
            <Heading>How to get started</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
        </Column>
      </Row>
    </Container>
  );
};
