import { AuthContext } from 'App';
import { LogoLink } from "components/headers/light.js";
import { useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import 'styled-components/macro';
import "twin.macro";
import tw from "twin.macro";

import logo from 'p_images/logo-square.svg';

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PageLink = tw(RouterLink)`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
    NavLink
)`text-gray-100 bg-secondary-200 px-6 py-3 border-none rounded hocus:bg-secondary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0 cursor-pointer`;


export default ({ openLoginModal, internal = false }) => {
    const authContext = useContext(AuthContext)

    const logout = () => {
        authContext.logout()
    }

    return (
        <NavRow>
            <LogoLink href="/">
                <img src={logo} alt="" css={[tw`rounded-md drop-shadow`]} />
                Poli
            </LogoLink>
            <div css={[tw`flex flex-wrap justify-center lg:justify-end items-center -mr-12`]}>
                <NavLink href="/#Home">
                    Home
                </NavLink>
                {!internal && (
                    <>
                        <NavLink href="#Features">
                            Features
                        </NavLink>
                        <NavLink href="#HowTo">
                            How to
                        </NavLink>
                        <NavLink href="#Clients">
                            Messengers
                        </NavLink>
                        <NavLink href="#About">
                            About Poli
                        </NavLink>
                        <NavLink href="mailto:feedback@poli.xyz" target="_blank">
                            Feedback
                        </NavLink>
                    </>
                )}
                <div tw="md:hidden flex-100 h-0"></div>
                {!authContext.isAuthenticated ? (
                    <PrimaryNavLink onClick={openLoginModal}>
                        Login
                    </PrimaryNavLink>
                ) : (
                    <>
                        <PageLink to="/app/users/me">
                            Profile
                        </PageLink>
                        <PrimaryNavLink onClick={logout}>
                            Logout
                        </PrimaryNavLink>
                    </>
                )}
            </div>
        </NavRow>
    )
}