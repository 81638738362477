import { Container, Content2Xl } from "components/misc/Layouts";
import AnimationRevealPage, { AnimatedSlideInComponent } from "helpers/AnimationRevealPage.js";
import React from 'react';
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import styled from "styled-components";
import 'styled-components/macro';
import "styles/bot.css";
import "twin.macro";
import tw from "twin.macro";

import Modal from 'helpers/ReactModalAdapter';
import About from 'p_components/about';
import Clients from 'p_components/clients';
import Features from 'p_components/features';
import Footer from 'p_components/footer';
import Hero from 'p_components/hero';
import HowTo from 'p_components/how-to';
import Nav from 'p_components/nav';

import ActionProvider from "chatbot/ActionProvider";
import MessageParser from "chatbot/MessageParser";
import config from "configs/chatbotConfig";

import TelegramIcon from "p_images/icon-telegram.svg";


const ContainerGray = tw(Container)`bg-gray-200 -mx-8 py-8 px-8`
const ContainerGrayFirst = tw(ContainerGray)`-mt-8`
const ContainerBeige = tw(Container)`bg-beige -mx-8 py-8 px-8`
const ContainerSecondary = tw(Container)`bg-secondary-500 -mx-8 py-8 px-8 text-primary-500`
const ContainerBlueGradient = styled(Container)`
    ${tw`bg-secondary-500 -mx-8 py-8 px-8 text-primary-500`}

    background: rgb(6,191,197);
    background: linear-gradient(322deg, #0B182B 20%, #072A47 80%);
`
const StyledModal = styled(Modal)`
  &.modal__overlay,
  &.loginModal__overlay  {
    ${tw`fixed inset-0 z-50`}
  }
  &.loginModal__overlay {
    ${tw`fixed z-50 h-96`}
  }
  &.loginModal__content,
  &.modal__content {
    ${tw`sm:mx-auto sm:my-4 sm:max-w-lg absolute inset-0 flex justify-center items-center sm:rounded-lg bg-gray-200 outline-none sm:drop-shadow-lg`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-2 mr-2 w-8 h-8 hocus:text-primary-500 bg-white drop-shadow rounded-full`;

const LoginProvider = styled.a`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}

  .imageContainer {
    ${tw`border-2 text-center rounded-full p-3 flex-shrink-0`}

    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`sm:ml-6 mt-1`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
    sup {
      ${tw`text-sm text-gray-500`}
    }
  }

  .description {
    ${tw`mt-1 sm:mt-4 text-secondary-300 text-sm whitespace-nowrap`}
    code {
      ${tw`text-secondary-500 rounded-md`}
    }
  }
`;


export default () => {
    const [chatModalIsOpen, setChatModalIsOpen] = React.useState(false);
    const [loginModalIsOpen, setLoginModalIsOpen] = React.useState(false);

    function openChatModal(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setChatModalIsOpen(true);
    }

    function closeChatModal(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setChatModalIsOpen(false);
    }

    function openLoginModal(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setLoginModalIsOpen(true);
    }

    function closeLoginModal(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setLoginModalIsOpen(false);
    }
    return (
        <AnimationRevealPage disabled>
            <ContainerGrayFirst>
                <Content2Xl>
                    <Nav openLoginModal={openLoginModal} />
                </Content2Xl>
                <Content2Xl>
                    <Hero />
                </Content2Xl>
            </ContainerGrayFirst>
            <ContainerBlueGradient id="Features">
                <Content2Xl>
                    <AnimatedSlideInComponent direction="left"><Features /></AnimatedSlideInComponent>
                </Content2Xl>
            </ContainerBlueGradient>
            <ContainerGray id="HowTo">
                <Content2Xl>
                    <AnimatedSlideInComponent direction="right"><HowTo /></AnimatedSlideInComponent>
                </Content2Xl>
            </ContainerGray>
            <ContainerGray id="Clients">
                <Content2Xl>
                    <AnimatedSlideInComponent direction="left"><Clients openModal={openChatModal} /></AnimatedSlideInComponent>
                </Content2Xl>
            </ContainerGray>
            <ContainerBlueGradient id="About">
                <Content2Xl>
                    <AnimatedSlideInComponent direction="right"><About /></AnimatedSlideInComponent>
                </Content2Xl>
                <Content2Xl>
                    <Footer />
                </Content2Xl>
            </ContainerBlueGradient>
            <StyledModal isOpen={chatModalIsOpen} onRequestClose={closeChatModal} className="modal">
                <Chatbot
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
                <CloseModalButton onClick={closeChatModal}>
                    x
                </CloseModalButton>
            </StyledModal>
            <StyledModal isOpen={loginModalIsOpen} onRequestClose={closeLoginModal} className="loginModal">
                <div>
                    <LoginProvider>
                            <span className="imageContainer">
                                <img src={TelegramIcon} alt="telegram" width="40px" />
                            </span>
                            <span className="textContainer">
                                <span className="title">Login via Telegram</span><br />
                                <span>
                                    Send <code>/login</code> in your chat with Poli.
                                </span>
                            </span>
                    </LoginProvider>
                    {/* <center>
                        <TelegramLoginButton
                            botName="poli_dev_bot"
                            requestAccess={true}
                            dataOnauth={(user) => console.log(user)}
                            // dataAuthUrl="https://dev.poli.xyz/app/profile/me"
                        />
                        <small>Telegram login: If you see no login button, please deactivate any AdBlockers.</small>
                    </center> */}
                </div>
                <CloseModalButton onClick={closeLoginModal}>
                    x
                </CloseModalButton>
            </StyledModal>
        </AnimationRevealPage>
    )
}