import { createChatBotMessage } from "react-chatbot-kit";

import BotAvatar from 'p_images/poli-icon-no-shadow.png';
import UserAvatar from "p_images/user-icon.png";

const config = {
  botName: "Poli",
  initialMessages: [
    createChatBotMessage(
      "Hi 👋! I'm Poli, your interactive language learning partner.\n" +
      "This web client does not support all current features: use telegram or viber for the full experience 💪\n" +
      "Note that we store our conversations to improve my language teaching skills.\n"
    ),
    createChatBotMessage("Say hello to start chatting!")
  ],
  state: {
    started: true
  },
  customComponents: {
    botAvatar: () => <div class="react-chatbot-kit-bot-avatar-box"><img src={BotAvatar} width="60px" height="60px" /></div>,
    userAvatar: () => <div class="react-chatbot-kit-user-avatar-box"><img src={UserAvatar} width="60px" height="60px" /></div>,
  }
}

export default config